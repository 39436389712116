.InputsToggler {
  .is-open {
    .AccordionSidebarListHeader {
      .arrow-icon {
        transform: rotate(0);
      }
    }
  }

  .inputs-toggler {
    .Collapsible {
      .SwitchButton {
        cursor: pointer;
      }
      .switch-button {
        cursor: pointer;
      }
    }
  }

  .inputs-toggler-container {
    max-height: 250px;
    overflow: auto;
  }

  .inputs-toggler-wrapper {
    padding: 0 27px 0 40px;
  }

  .inputs-toggler-container {
    display: flex;
    flex-direction: column;
  }
  .Checkbox {
    margin-top: 20px;
  }

  margin-bottom: 20px;

  .Checkbox {
    display: block;
  }
}
