.GroupedBarLegend {
  .grouped-bar-legend {
    display: flex;
    flex-wrap: wrap;
  }

  .GroupedBarLegendItem {
    margin: 0 0 10px 0;
  }
}

@media (max-width: 1300px) {
  .GroupedBarLegend {
    .GroupedBarLegendItem {
      &:not(:last-child) {
        margin: 0 0 10px 0;
      }
    }
  }
  
}

.pdf {
  .GroupedBarLegend {
    padding: 10px 0 0;
    margin: 0;

    .GroupedBarLegendItem {
      margin: 0;

      .label {
        width: auto;
      }
    }
  }
}
