.QrPage {
  height: 100vh;
  width: 100%;

  .form-wrapper {
    height: 330px;
    width: 283px;
  }

  .qr-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
  }

  .static-token-form-banner {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    color: var(--strange-black);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  .Tabs {
    .tab-item-enter-active,
    .tab-item-exit-active,
    .tab-item-appear-active {
      .ModalWindow {
        > div {
          > div {
            background-color: transparent;
            transition: background-color 0s;
          }
        }
      }
    }
  }
}
