.btnWrapper {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: end;
}
.container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap: 30px;
  overflow-x: auto;
}
.fileWrapper {
  display: flex;
  flex-direction: row;
  width: 150px;
  min-width: 150px;
  height: 50px;
  border: 1px solid rgb(171, 171, 171);
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
