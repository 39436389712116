.BuildingsMap {
  z-index: 1;
  position: relative;
  height: 100vh;
  .buildings-map {
    width: 100%;
    min-height: 100%;
  }
  .leaflet-container {
    width: 100%;
    min-height: 100vh;
  }
  .leaflet-marker-icon {
    background: yellow;
  }
  .custom-leaflet-marker {
    width: 30px;
    height: 30px;
    background-color: blue;
  }

  &.pdfMode {
    .leaflet-container {
      min-height: 65vh;
      //max-height: 100%;
    }
  }
}
