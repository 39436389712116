.container {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.cardContainer,
.cardContainerModal {
  width: 100%;
  height: calc(100% - 15px);
  box-shadow: 0px 2px 8px rgba(59, 59, 59, 0.25);
  border-radius: 5px;
  margin-bottom: 20px;
}

.cardContainerModal:hover {
  cursor: pointer;
  background-color: #f4f5f7;
}
