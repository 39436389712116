.filterSection {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .header {
    margin-bottom: 16px;
    font-size: 1rem;
    line-height: 1;
    font-weight: 500;
    color: var(--black);
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .item {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


