.GroupedBarLegendItem {
  .grouped-bar-legend-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 30px;
  }

  .label {
    font-size: 18px;
    line-height: 1.25;
    font-weight: 500;
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .color-marker {
    display: block;
    min-width: 22px;
    max-width: 22px;
    height: 22px;
    margin-right: 15px;
    border-radius: 50%;
  }
}

.pdf {
  .GroupedBarLegendItem {
    .label {
      font-size: 12px;
      line-height: 16px;
    }
    .color-marker {
      min-width: 12px;
      max-width: 12px;
      height: 12px;
    }
  }
}
