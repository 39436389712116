@import "assets/mixins";

.container {
  height: 100%;
  overflow-y: auto;
  padding: 16px 16px 0 16px;

  @include customScrollbar;

  @media print {
    overflow: hidden;
  }

  .title {
    margin-bottom: 16px;
  }
}
