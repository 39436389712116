.FormikInput {

  .formik-input {
    width: 100%;
    max-width: 530px;
    border: 1px solid #C9C9C9;
    border-radius: 5px;
    font-size: 19px;
    line-height: 1;
    color: #686868;
    padding: 0 20px;
    display: block;
    height: 60px;
    transition: border-color .3s ease-in-out,box-shadow .3s ease-in-out;

    &:not(:first-child) {
      margin-top: 30px;
    }

    &:focus {
      border-color: var(--turquoise);
      box-shadow: 0 0 0 0.2rem rgba(0, 170, 210, .5);
    }
  }
}
