.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90%;
  padding: 10px 40px 40px 40px;
  height: 90%;

  .graph {
    width: 100%;
    height: 95%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:
      'topLeft topRight'
      'bottomLeft bottomRight';
      
    .section {
      margin: 5px;
      padding: 15px;
      border: 1px solid #c9c9c9;
      box-shadow: 0px 2px 8px rgba(59, 59, 59, 0.25);
      border-radius: 5px;
    } 

    .leftSection {
      grid-column: topLeft/topLeft;
      margin-right: 16px;
    }
    .rightSection {
      grid-column: topRight/topRight;
      margin-left: 16px;
    }
  }

  .closeBtn {
    position: relative;
    left: calc(100% - 40px);
  }
}
