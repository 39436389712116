.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 95%;
  height: 50px;
  cursor: pointer;
}
.row > .object {
  width: 40%;
  display: flex;
  border-right: 1px solid #cce9f2;
  flex-direction: row;
  justify-content: center;
}
.row > .object ~ .object {
  width: 20%;
}
.object:last-of-type {
  border: none;
}
.actionsBtnWrapper {
  width: 90%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.iconWrapper {
  width: 5%;
  border-left: 1px solid #cce9f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
