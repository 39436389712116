.container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    'topLeft topRight'
    'bottomLeft bottomRight';

  .topLeft {
    grid-area: topLeft;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .topRight {
    grid-area: topRight;
    margin-left: 16px;
    margin-bottom: 16px;
  }

  .bottomLeft {
    grid-area: bottomLeft;
    margin-right: 16px;
    margin-top: 16px;
  }

  .bottomRight {
    grid-area: bottomRight;
    margin-left: 16px;
    margin-top: 16px;
  }

  .top {
    grid-row: topLeft/topLeft;
    grid-column: topLeft/topRight;
    margin-bottom: 16px;
  }

  .bottom {
    grid-row: bottomLeft/bottomLeft;
    grid-column: bottomLeft/bottomRight;
    margin-top: 16px;
  }

  .left {
    grid-row: topLeft/bottomLeft;
    grid-column: topLeft/topLeft;
    margin-right: 16px;
  }

  .leftDashboard,
  .rightDashboard {
    margin-bottom: 10px;
    height: 500px;
    align-items: stretch;
  }

  .rightDashboard {
    grid-column: topRight/topRight;
  }

  .leftDashboard {
    grid-column: topLeft/topLeft;
    margin-right: 16px;
  }

  .right {
    grid-row: topRight/bottomRight;
    grid-column: topRight/topRight;
    margin-left: 16px;
  }

  .wholePage {
    grid-row-start: topLeft;
    grid-row-end: bottomLeft;
    grid-column-start: topLeft;
    grid-column-end: topRight;
    // z-index: 1000;
  }
}

.isPdf {
  font-size: 10px;
}

.isDashboard {
  grid-template-rows: auto;
}

.section {
    margin: 5px;
    padding: 15px;
    border: 1px solid #c9c9c9;
    box-shadow: rgba(0, 0, 0, 20%), 0px 2px 2px 0px rgba(0, 0, 0, 14%), 0px 1px 5px 0px rgba(0, 0, 0, 12%);
    border-radius: 5px;
    overflow: auto;
  }

.leftSection {
  grid-column: topLeft/topLeft;
  margin-right: 16px;
}

.rightSection {
  grid-column: topRight/topRight;
  margin-left: 16px;
}

.widgetContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
