.StatsTableCell {
  font-size: 15px;
  line-height: 1;
  color: var(--black);
  font-weight: 500;

  &.pdf {
    font-size: 12px;
  }
}
