.ReportHistoryPage {
  .report-history-page {
    display: flex;
    position: relative;
  }

  .history-content {
    padding: 30px 60px 120px 30px;
  }

  .history-content-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  .nav-menu {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: var(--white);
  }

  .PageHeader {
    .title {
      margin-bottom: 50px;
    }
  }
}

@media (max-width: 1600px) {
  .ReportHistoryPage {
    .history-content {
      padding-right: 20px;
    }
    .HistoryReportListItem {
      margin-right: 20px;
    }
  }
}
