.mapContain {
  width: 70%;
  height: 95%;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90%;
  padding: 10px 40px 40px 40px;
  height: 90%;
}

.title {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.closeBtn {
  position: 'absolute';
  right: '30px';
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.body {
  display: 'flex';
  flex-direction: 'row';
  height: '100%';
}
