.WithIconLink {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;

  .with-icon-title {
    &:hover {
      .label {
        color: var(--turquoise);
      }
    }
  }

  &.is-big {
    transition: background-color 0.5s ease;
    padding: 10px;
    border-radius: 5px;

    &:hover {
      background-color: silver;
    }

    .icon {
      position: relative;
      left: 0;
      top: 0;
      transform: translateY(0);
      width: 50px;
      height: auto;
    }

    .label {
      padding-left: 0;
      font-size: 18px;
      line-height: 24px;
      margin-top: 15px;
    }

    .with-icon-title {
      flex-direction: column;
    }
  }

  &.is-active {
    .label {
      color: var(--turquoise);
    }
  }

  &.is-disabled {
    opacity: 0.5;
  }

  .with-icon-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }

  .with-icon-title {
    position: relative;
    display: flex;
    align-items: center;
  }

  .label {
    font-size: 14px;
    line-height: 20px;
    transition: color 0.2s ease;
    padding-left: 40px;
    position: relative;
  }

  .icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: auto;
    height: auto;
  }
}
