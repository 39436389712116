.SummaryPage {
  &.no-map {
    .map-container {
      display: none;
    }
    .content-container {
      max-width: 100%;
    }

    .single-bar-label {
      min-width: 200px;
      max-width: 200px;
    }

    .RoundArrowButton {
      left: 305px;
    }

    .StackedPercentNav {
      padding-left: 200px;
    }

    .FiltersInBlockButton {
      left: auto;
      top: auto;
      position: relative;
      transform: translateX(0);
      margin-bottom: 35px;
    }

    .content-container-header {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .PageHeader {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        min-width: 450px;
      }
    }

    .DesksCards {
      text-align: right;
    }
  }

  .GraphDisplay {
    overflow: hidden;
  }

  .BuildingsMap {
    height: 100%;
    .leaflet-container {
      min-height: calc(100vh - 47px);
    }
  }

  .content-container-header {
    display: flex;
    margin-bottom: 115px;
    .PageHeader {
      display: none;
    }
  }

  .DesksCards {
    .DesksCard {
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .GroupedBarLegend {
    margin-top: 30px;

    .grouped-bar-legend {
      .GroupedBarLegendItem {
        &:first-child {
          order: 1;
        }
      }
    }
  }

  .graphs-wrapper {
    width: 100%;
    max-width: 100%;
    margin-top: 35px;
    position: relative;
    z-index: 1;
  }

  .single-bar {
    width: 100%;
    max-width: 100%;
    height: 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .single-graph {
      width: calc(100% - 100px);
      pointer-events: none;
      > * {
        width: 100% !important;
      }
    }

    &-label {
      min-width: 100px;
      max-width: 100px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: var(--black);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .summary-page {
    display: flex;
    position: relative;
  }

  .summary-content-scroll {
    height: 100vh;
    width: 100%;
    overflow: auto;
  }

  .summary-content {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    position: relative;

    .resetBtn {
      margin-top: 50px;
      color: white;
      width: 192px;
      line-height: 25px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .nav-menu {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 102;
    background-color: var(--white);
    width: 100%;
  }

  .map-container {
    min-width: 574px;
    max-width: 574px;
    height: calc(100vh - 47px);
    position: sticky;
    top: 47px;
  }

  .PageHeader {
    position: absolute;
    top: 45px;
    left: 20px;
    z-index: 10;
    margin-bottom: 0;

    .title {
      margin-bottom: 0;
    }
  }

  .Loader {
    &.fixed {
      background: transparent;
    }
  }

  .FiltersInBlockButton {
    position: fixed;
    top: 187px;
    left: 325px;
    z-index: 10;
  }

  .with-border-bar {
    border-bottom: 2px solid var(--summary-graph-border);
    border-left: 2px solid var(--summary-graph-border);
    padding: 0 0 40px 20px;
  }

  .one-graph-wrapper {
    position: relative;
    .with-nav-bar {
      border-bottom: 2px solid var(--summary-graph-border);
      border-left: 2px solid var(--summary-graph-border);
      padding: 0 0 45px 15px;
      position: relative;

      .StackedPercentNav {
        position: absolute;
        left: 15px;
        bottom: 0;
        width: calc(100% - 15px);
      }
    }
  }

  .content-container {
    width: 100%;
    max-width: calc(100% - 574px);
    position: relative;

    &-inner {
      padding: 40px 40px 15px;
    }
  }

  .RoundArrowButton {
    position: fixed;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: calc(305px + 574px);
    z-index: 1;
  }

  .table-section {
    margin-top: 60px;
    .summary-title {
      margin-bottom: 15px;
    }
  }

  .count-division-legend {
    font-size: 18px;
    font-weight: 500;
    color: var(--dark-grey);
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }

  .ModalWindow {
    .modal-window {
      padding: 0;
    }
  }
}

@media (max-width: 1900px) {
  .SummaryPage {
    &.no-map {
      .PageHeader {
        margin-bottom: 30px;
      }

      .content-container-header {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

@media (max-width: 1600px) {
  .SummaryPage {
    .map-container {
      min-width: 400px;
      max-width: 400px;
    }
    .content-container {
      max-width: calc(100% - 400px);
    }
    .FiltersInBlockButton {
      min-width: 340px;
    }
    .RoundArrowButton {
      left: calc(305px + 400px);
    }
  }
}

@media (max-width: 1480px) {
  .SummaryPage {
    &.no-map {
      .RoundArrowButton {
        left: 305px;
      }
    }
  }
}

@media (max-width: 1430px) {
  .SummaryPage {
    .map-container {
      min-width: 350px;
      max-width: 350px;
    }

    .RoundArrowButton {
      left: calc(305px + 350px);
    }
    .FiltersInBlockButton {
      min-width: 320px;
    }

    .content-container {
      max-width: calc(100% - 350px);
    }
  }
}

