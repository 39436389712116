@import 'assets/mixins';

.widgetContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.title {
  height: 20%;
  width: 100%;
  justify-content: end;
  padding-right: 5%;
  padding-top: 2%;
}
.subtitle {
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5%;
}
.description {
  margin-top: 1%;
}
.dataWrapper {
  display: flex;
}

.column2 {
  margin-left: 20px;
}
