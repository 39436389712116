.StaticTokenForm {
  .static-token-form {
    margin-top: 30px;

    &.disabled {
      .FormButton {
        background-color: #808088;
        .form-button {
          cursor: not-allowed;
        }
      }
    }
  }

  .FormButton {
    margin-top: 30px;
  }
}
