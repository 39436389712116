@mixin sidebarContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 16px 0 0 16px;
}

@mixin sidebarText {
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.87);
}

@mixin listItem {
  padding-left: 0 !important;

  .icon {
    min-width: 40px !important;
  }

  .text {
    @include sidebarText;

    &:hover,
    &.active {
      color: #00aad2;
    }
    &.disable {
      color: #bbbbbb;
    }
    &.unpublished {
      color: #9A50B7;
    }
  }
}

@mixin listItemSlider {
  @include listItem();
  border-bottom: 1px solid #E5E5E5;
  cursor: pointer;

  .arrow {
    color: rgba(0, 0, 0, 0.54);
  }
}

@mixin listSubItem {
  padding-left: 0 !important;

  .textContainer {
    padding-left: 40px !important;
  }

  .text {
    @include sidebarText;

    &:hover,
    &.active {
      color: #00aad2;
    }

    &.unpublished {
      &:hover,
      &.active {
        color: #9A50B7;
      }
    }
  }
}
