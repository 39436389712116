.TabsNav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;

  .TabsNav-message {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    color: tomato;
  }

  .tab-nav-item {
    &:not(.active-tab) {
      cursor: pointer;
      color: #3a3a3a;
    }

    &.active-tab {
      color: #00aad2;
    }

    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    transition: color 0.5s ease;
  }
}

.TabsPosition {
  justify-content: center;
  align-items: center;
}
