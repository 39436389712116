.agilityDataCardsContainer {
  .agilityDataCard {
    width: 190px;
    height: 145px;
    padding: 15px;
    border-radius: 7px;

    font-weight: 500;
    color: var(--white);
    text-align: center;
  };

  .agilityDataCardLabel {
    font-size: 18px;
    line-height: 25px;
    color: inherit;
  }

  .agilityDataCardValue {
    font-size: 30px;
    line-height: 37px;
    color: inherit;
  }

  &.pdfMode {
    .agilityDataCard {
      width: 170px;
      height: 135px;
    }

    .agilityDataCardLabel {
      font-size: 16px;
      line-height: 20px;
    }

    .agilityDataCardValue {
      font-size: 28px;
      line-height: 32px;
    }
  }
}

