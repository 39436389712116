.HistoryReportListNode {
  margin: 0 5px;

  .history-report-list-node {
    min-width: 120px;
    transition: width 0.2s ease;
  }

  .header {
    min-height: 37px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-bottom: 1px solid #afafaf;
    width: 100%;
  }

  .title {
    color: rgba(0, 0, 0, 0.62);
    font-size: 13px;
    font-weight: 500;
  }

  .content {
    min-height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 100%;
  }

  .label {
    color: var(--black);
    font-size: 19px;
    line-height: 1;
    font-weight: 500;
  }

  .percent {
    color: var(--black);
    font-size: 19px;
    line-height: 1;
    font-weight: 500;
  }

  &.with-percent {
    .label {
      font-size: 13px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.62);
      font-weight: 500;
    }
  }
}

@media (max-width: 1600px) {
  .HistoryReportListNode {
    margin: 0 10px;
    .history-report-list-node {
      min-width: 95px;
    }
    .title {
      font-size: 11px;
    }
  }
}
