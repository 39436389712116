.header {
  display: flex;
  flex-wrap: nowrap;
}

.deleteBtn {
  margin-right: 73px !important;
}

.selectAll {
  margin-left: auto !important;
}

.privateContainer{
  display: 'flex';
  flex-direction: 'row';
  width: '120px';
  align-items: 'center';
  justify-content: 'center';
  margin-top: '10px';
}
