.SwitchButton {
  min-width: 230px;
  max-width: 330px;
  height: 57px;
  position: relative;
  border-radius: 34.5px;
  overflow: hidden;
  padding-left: 72px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  .icon {
    position: absolute;
    display: block;
    top: 0;
    left: -2px;
    height: 57px;
    width: auto;
  }

  .switch-button {
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
    font-size: 19px;
    font-weight: 500;
  }
}
