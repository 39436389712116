.root {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;

  display: grid;
  grid-template-columns: 307px auto;
  grid-template-rows: 48px auto;
  grid-template-areas:
    "sidebar header"
    "sidebar content";

  .header {
    grid-area: header;
  }

  .sidebar {
    grid-area: sidebar;

    border-right: 1px solid #dfdfdf;
    overflow: auto;
    overflow-x: hidden;
  }

  .content {
    grid-area: content;

    position: relative;
    padding: 32px;
    overflow: auto;
    overflow-x: hidden;
  }
}