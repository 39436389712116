@import 'assets/mixins';

.container,
.containerModal {
  width: 100%;
  height: 100%;
  padding: 16px;

  overflow: auto;
  @include customScrollbar;

  .gridContainer {
    flex-wrap: nowrap;
    height: calc(100% + 16px);
  }

  .innerContainer {
    flex-grow: 1;
    display: flex;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
  }
  .textInfo{
    margin-top: 20px;
    color: #c6a40c;
  }
}

.containerModal:hover {
  cursor: pointer;
  background-color: #f4f5f7;
}
