@import 'assets/mixins';

.container {
  max-width: 1043px;
  .emptyGraphInner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e4e4e4;
    height: 400px;
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: auto;
  }
  .graphWrapper {
    position: relative;
    display: flex;
    border: 1px solid #e4e4e4;

    .graphInner {
      flex-grow: 1;
      overflow-x: auto;
      overflow-y: hidden;
      border-left: 1px solid #e4e4e4;

      @include customScrollbar;

      .graph {
        min-height: 590px;
        background: var(--white);
        padding: 0;
      }
    }
  }

  &.pdfMode {
    .graphWrapper {
      margin-bottom: 8px;

      .graphInner {
        .graph {
          min-height: 300px;
        }
      }
    }
  }
}
