@import 'assets/mixins';

.container {
  margin-top: 15px;

  .graphSection {
    position: relative;
    display: flex;
    border: 1px solid #e4e4e4;
    min-width: 200px;
    height: 450px;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .title {
    padding-bottom: 15px;
  }

  .graphContainer {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 450px;
    border: 1px solid #e4e4e4;
    margin-bottom: 16px;

    .barSection {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      border-left: 1px solid #e4e4e4;
      @include customScrollbar;

      .oneDayContainer {
        max-width: calc(100% - 4px);
        min-width: calc(100% - 4px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        &:first-child {
          .oneDayLabel {
            border-left: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:last-child:nth-child(n + 5) {
          .oneDayLabel {
            border-right: 0;
          }
        }

        .oneDayGraph {
          flex-grow: 1;
          margin-right: -4px;
        }
        .labelWrapper {
          margin-top: 8px;
          height: 45px;
          flex-wrap: nowrap;

          .oneDayLabel {
            padding: 0 4px;
            line-height: 40px;
            text-align: center;
            border: 1px solid #e4e4e4;
            border-bottom: none;
            @include ellipsis();

            &:first-child {
              border-left: none;
            }

            &:not(:last-child) {
              border-right: none;
            }
          }
        }
      }
    }
  }

  &.pdfMode {
    .graphContainer {
      border-radius: 5px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      height: 250px;
      margin-bottom: 8px;
    }

    .barSection {
      .oneDayContainer {
        margin-right: -8px;
        .labelWrapper {
          height: 25px;
          .oneDayLabel {
            height: 25px;
            line-height: 20px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
