.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  background: url("https://theplanetd.com/images/Tallest-mountains-in-the-world.jpg")
  center center / cover no-repeat;
  text-align: right;

  .text {
    color: inherit;
  }
}
