.btnWrapper {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: end;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100% - 67px);
}
.mergedContainer{
  width: 50%;
}
.fileWrapper {
  display: flex;
  flex-direction: row;
  width: 150px;
  height: 50px;
  border: 1px solid rgb(171, 171, 171);
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.tableContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
