.NABlock {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .n-a-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-label {
      font-size: 16px;
      line-height: 20px;
      color: var(--black);
    }
  }
}
