.container {
  display: flex;
  flex-direction: row;

  .search {
    display: flex;
    width: 300px;
    margin-right: 15px;
  }
}
