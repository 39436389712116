.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.button {
  position: absolute !important;
  right: -40px !important;
  top: -40px;
}

.wrapper2 {
  position: relative;
  width: 90%;
  height: 85%;
}
