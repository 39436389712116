.container {
  position: relative;
  z-index: 99;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.scaleWrapper {
  flex-grow: 1;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
}

.scaleItem {
  display: flex;
  justify-content: center;
  align-items: center;
}