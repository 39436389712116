@import '../../../../assets/mixins';

.cardBody {
  width: 150px;
  height: 100%;
  max-height: 330px;

  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    width: 100%;

    font-size: 1rem;
    line-height: 28px;
    min-height: 28px;
    letter-spacing: 0.5px;

    @include ellipsis;
  }

  .subTitle {
    width: 100%;

    font-size: 12px;
    line-height: 16px;
    min-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);

    @include ellipsis;
  }

  .icon {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;

    height: 40px;
  }

  .content {
    width: 100%;
    margin-top: 25%;

    font-size: 34px;
    line-height: 40px;
    min-height: 40px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);

    @include ellipsis;
  }

  .subContent {
    width: 100%;
    min-height: 16px;

    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);

    @include ellipsis;
  }
}
