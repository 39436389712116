.container {
  max-height: 100%;
  min-height: 70%;
  width: 70%;
  max-width: 650px;
  margin: 0 auto;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:global(.absolute) {
    height: unset;
    width: unset;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:global(.column) {
    flex-direction: column;
  }
};
