.sectionWrapper {

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .sectionName {
    margin-bottom: 12px;
  }
}
