.PasswordNotification {

  .password-notification {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .password-title {
    font-size: 51px;
    font-weight: 500;
    margin-bottom: 25px;
    color: var(--black);
  }

  .password-message {
    font-size: 33px;
    color: var(--grey);
  }
}
