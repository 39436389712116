.RoundArrowButton {
  height: 51px;
  width: 51px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--white);

  &.invert-arrow {
    .grey-arrow {
      transform: rotate(180deg);
    }
  }

  .round-arrow-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .grey-arrow {
    display: block;
    width: 10px;
    height: auto;
  }
}
