@import "assets/mixins";

.container {
  @include pdfContainer;

  .servicePage {
    @include pdfPage;
  }

  .pdfPage {
    @include pdfPage;
  }

  .hasPageCounter:before {
    @include pdfPageCounter;
    bottom: 10px;
    right: 25px;
  }
}
