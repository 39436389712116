@import "assets/mixins";

.container,
.containerModal {
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  .header {
    padding: 16px;
  }

  .tableContainer {
    flex-grow: 1;
    @include customScrollbar;

    th, td {
      min-width: unset;
      padding: 5.9px 8px;
      height: auto;
    }
  }
}

.containerModal:hover {
  cursor: pointer;
  background-color: #f4f5f7;
}