@import 'assets/mixins';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 750px;
  max-width: 750px;
  padding: 40px;

  .checkbox {
    margin-right: 16px;
  }

  .title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  .content {
    max-height: 290px;
    overflow-x: auto;

    @include customScrollbar;

    .item label {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      .value {
        @include ellipsis;
        cursor: pointer;
      }
    }
  }

  .actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}
