.listContainer {
  margin-top: 10px;
  display: flex;
  padding-left: 10px;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 100px;
  box-shadow: 2px 3px 30px 1px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.headerItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}
.link {
  margin-top: 5px;
  margin-left: 18px;
}
.url {
  margin-top: 5px;
  font-size: 18px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding-top: 5%;
}

.textField {
  margin-top: 40px;
}

.error {
  color: red;
  text-transform: capitalize;
}

.name {
  color: red;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: end;
  margin-top: 40px;
  width: 100%;
}
.sbmtBtn {
  align-self: flex-end;
}
.cancelBtn {
  margin-right: 20px !important;
}
.container {
    height: 500px;
}
