.NewPasswodForm {

    .new-passwod-form {   
        
        &.disabled {
            .FormButton {
                background-color: #808088;
                .form-button {
                    cursor: not-allowed;
                }
            }
        }
    }

    .FormButton {
        margin-top: 70px;
    }

    .FormikInput {
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
}
