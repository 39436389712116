.container {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
  margin-bottom: 32px;

  .cardItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 8px;
    padding: 16px;

    flex-basis: 212px;
    max-width: 212px;
    height: 127px;
    border-radius: 8px;

    .icon {
      flex-basis: 100%;
      height: 30px;
    }

    .label {
      flex-basis: 100%;
      font-size: 15px;
      line-height: 1.1;
      color: hsla(0,0%,100%,.52);
      text-align: center;
      font-weight: 500;
    }
  }

  &.pdfMode {
    margin-bottom: 8px;

    .cardItem{
      flex-basis: 150px;
      max-width: 150px;
      height: 100px;
      padding: 14px;

      .icon {
        height: 24px;
      }

      .label {
        font-size: 10px;
      }
    }
  }
}
