:root {
  --main-font: "Roboto", serif;
  --base-spacing: 0.25px;
  --transparency-black: rgba(0, 0, 0, 0.8);
  --error-color: #f91818;
  --turquoise: #00aad2;
  --light-turquoise: #6bc6df;
  --dim-turquoise: #58bfc7;
  --bright-turquoise: #51c3ca;
  --black: #000;
  --strange-black: #3a3a3a;
  --grey: #b6b6b6;
  --light-light-grey: #c4c4c4;
  --light-grey: #f7f7f7;
  --dark-grey: #4d4d4d;
  --dark-dark-grey: #333333;
  --sea-blue: #004352;
  --light-sea-blue: #106e86;
  --light-sea-blue-graph: #3d8c9d;
  --light-blue-graph: #9fc6cf;
  --light-grey-graph: #cee2e7;
  --dark-sea-blue: #0d6f85;
  --white: #fff;
  --almost-white: #f9f9f9;
  --pink: #c577c0;
  --dark-pink: #a2559d;
  --dark-gold: #f6a000;
  --light-green: #b4cc04;
  --crimson: #c4043f;
  --silver: #afafaf;
  --sky-blue: #bfe8ee;
  --earthly: #5c5c5c;
  --transparent-white: rgba(255, 255, 255, 0.52);
  --almost-white: #f1f1f1;
  --inactive-text: #d3d3d3;
  --graph-border: #e4e4e4;
  --size-input-border: #c9c9c9;
  --occupancy-bar-color: #34bbdb;
  --summary-graph-border: #cacaca;
  --nav-menu-border: #e5e5e5;

  --accordion-sidebar-text: rgba(0, 0, 0, 0.87);
  --accordion-sidebar-header-border: #e5e5e5;
}
