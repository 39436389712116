@import 'assets/mixins';

.container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  align-items: stretch;

  .item {
    margin-bottom: 16px;
    margin-right: 16px;
  }
}
.header {
  padding: 16px;
}
.emptyContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.justifyEnd {
  justify-content: flex-end;
}
