.ResetPasswordMailNotification {
    .reset-password-mail-notification {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .info-text {
        font-size: 51px;
        font-weight: 500;
        margin-bottom: 25px;
        color: var(--black);
        text-align: center;
    }

    .cta-text {
        font-size: 33px;
        color: var(--grey);
        text-align: center;
    }
}
