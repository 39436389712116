.HistoryReportListItem {
  display: inline-flex;
  position: relative;
  transition: box-shadow 0.2s ease;
  position: relative;
  background-color: var(--almost-white);
  border-radius: 8px;

  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    z-index: 10;

    .HistoryReportPopup {
      height: auto;
      opacity: 1;
      transform: translateY(0px);
      min-height: 50px;
      padding-top: 20px;
      overflow: visible;
    }
  }

  &:last-child {
    .HistoryReportPopup {
      top: auto;
      bottom: 100%;
      transform: translateY(-20px);
    }

    &:hover {
      .HistoryReportPopup {
        height: auto;
        opacity: 1;
        transform: translateY(0px);
        min-height: 50px;
        padding-bottom: 20px;
        overflow: visible;
      }
    }
  }

  .report-link {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  .history-report-list-item {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .nodes {
    display: flex;
  }

  .HistoryReportListTitle {
    margin-right: 80px;
  }

  .HistoryReportListNode {
    &:last-child {
      margin-right: 3px;
    }
  }
}

@media (max-width: 1600px) {
  .HistoryReportListItem {
    .HistoryReportListTitle {
      margin-right: 50px;
    }
    .HistoryReportListNode {
      &:last-child {
        margin-right: 10px;
      }
    }
  }
}