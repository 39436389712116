@import 'assets/mixins';

.container {
  display: flex;
  flex-wrap: wrap;

  .reportsWrapper {
    margin-right: auto;

    .reportListContainer {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      width: 394px;
      max-width: 400px;

      &:nth-child(2) {
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }
  }

  .filtersWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .filterContainer {
      height: 585px;
      max-height: 585px;
      position: relative;

      @include filterContainerBorder;

      &:first-child {
        margin-right: 32px;
      }


      @media (max-width: 1228px) {
        &:last-child{
          margin-right: 0;
        }
      }

      @media (max-width: 1127px) {
        &:last-child {
          margin-top: 32px;
        }
      }
    }

    @media (max-width: 1917px) {
      justify-content: flex-start;
    }

    @media (max-width: 1505px) {
      margin-top: 32px;
    }
  }
}
