@import 'assets/mixins';

.form {
  height: 100%;
}
.container {
  width: 35%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 67px);
}
.wrapper {
  width: 97%;
}
.title {
  @include ellipsis;
}
.inputWrapper {
  width: 100%;
}
.error {
  color: red;
}
.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sbmtBtn {
  align-self: flex-end;
}

.btnWrapper {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: end;
}
