@import 'assets/mixins';

.title {
  @include ellipsis;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.modalBody {
  padding: 20px 20px 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  height: 90%;
  overflow-y: auto;
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90%;
  border-radius: 10px;
  padding: 10px 40px 40px 40px;
  height: 90%;
}
.btnWrapper {
  width: 100%;
  display: flex;
  justify-content: end;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
}

.imageContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  >img{
    object-fit: cover;
    width: 30%;
    height: 200px;
  }
}

.avatar{
  >p{
    margin-bottom: 10px;
  }
  >img{
    margin-bottom: 10px;
  }
}