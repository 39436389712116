@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: var(--main-font);
  color: var(--transparency-black);
  line-height: 1.25;
  letter-spacing: var(--base-spacing);
}

th,
td {
  font-size: 15px;
  line-height: 1;
  color: var(--black);
  font-weight: 500;
  min-width: 160px;
  padding: 17px;
  letter-spacing: var(--base-spacing);
}

tr {
  &:nth-child(even) {
    background-color: #cde9f2;
    border-radius: 3px;
  }
}

thead {
  tr {
    background-color: #cde9f2;
    text-align: left;
  }
}

.editable-content {
  > * {
    font-size: 18px;
    line-height: 25px;
    color: #000;
    letter-spacing: var(--base-spacing);

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  ul,
  ol {
    li {
      list-style: square inside;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

.custom-list {
  li {
    font-size: 18px;
    line-height: 25px;
    list-style: disc inside;
    color: var(--transparency-black);
    letter-spacing: var(--base-spacing);

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
