.graphInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e4e4;
  height: 400px;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: auto;
}

.pdfGraphInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e4e4;
  height: 300px;
  width: 700px;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: auto;
}
.body {
  padding: 8px;
}

.pdfBody {
  padding-left: 8px;
  padding-bottom: 8px;
}
