.container {
  width: 100%;
  margin-left: 8px;
  .legendItem {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 50%;
    margin-right: 10px;

    .icon {
      margin-right: 16px;

      &.round {
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      &.line {
        min-width: 40px;
        height: 4px;
      }
    }
  }

  &.pdfMode {
    margin-top: 0;

    .legendItem {
      max-width: 100%;
      .icon {
        &.round {
          min-width: 15px;
          height: 15px;
        }

        &.line {
          min-width: 30px;
        }
      }
    }
  }
}
