@import 'assets/mixins';

.container {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  @include customScrollbar;
}

.imgContainer {
  width: 100%;
  height: 100%;
  padding: 25px;
  display: flex;
  flex-direction: row;
}

.barContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.emptyBar {
  width: 40px;
  height: 100%;
  border-style: solid;
  border-width: 0.5px;
  border-color: black;
  border-radius: 10px;
  display: flex;
}

.innerContainer {
  width: 100%;
  height: 90%;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    'topLeft topRight'
    'bottomLeft bottomRight';

  .topLeft {
    grid-area: topLeft;
    margin-top: 10px;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  .topRight {
    grid-area: topRight;
    margin-top: 10px;
    margin-left: 16px;
    margin-bottom: 16px;
  }

  .bottomLeft {
    grid-area: bottomLeft;
    margin-right: 16px;
    margin-top: 16px;
  }

  .bottomRight {
    grid-area: bottomRight;
    margin-left: 16px;
    margin-top: 16px;
  }
}
