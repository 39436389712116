@import 'assets/mixins';

.container {
  max-width: 1043px;

  .graphWrapper {
    position: relative;
    display: flex;
    border: 1px solid #E4E4E4;

    .graphInner {
      height: 500px;
      flex-grow: 1;
      overflow-x: auto;
      overflow-y: hidden;
      border-left: 1px solid #E4E4E4;

      @include customScrollbar;

      .graph {
        position: relative;
        height: calc(100% - 48px);
        background: var(--white);
        padding: 0;
      }

      .labelWrapper {
        margin-top: 8px;
        height: 40px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .dayLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #E4E4E4;
          border-bottom: none;

          &:first-child {
            border-left: none;
          }
        }
      }
    }
  }

  &.pdfMode {
    .graphWrapper {
      margin-bottom: 8px;

      .graphInner {
        height: 275px;
      }
    }
  }
}
