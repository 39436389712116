.StatsTable {
  display: inline-block;
  ::-webkit-scrollbar {
    width: 17px;
  }

  .ReactTable .rt-tbody {
    overflow: visible;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--turquoise);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--light-turquoise);
  }

  max-width: 100%;
  overflow: auto;

  .icon {
    transition: 0.2s ease;
  }

  .ReactTable .rt-thead {
    .rt-th {
      border: none;
      padding: 0 15px;

      &.-sort-asc {
        box-shadow: none;

        .icon {
          transform: translateY(-5px);
        }
      }

      &.-sort-desc {
        box-shadow: none;

        .icon {
          transform: translateY(5px);
        }
      }
    }

    &.-header {
      background-color: #cde9f2;
      box-shadow: none;
    }
  }

  .rt-th {
    min-height: 49px;
    display: flex;
    align-items: center;

    &.-sort-asc {
    }
  }

  .rt-tr {
    min-height: 49px;
    border-radius: 3px;

    &.-even {
      background-color: #cde9f2;
    }
  }

  .rt-tbody {
    .rt-td {
      padding: 0 15px;
      display: flex;
      align-items: center;
      border: none;
    }
  }

  .ReactTable {
    border: none;

    .rt-tbody .rt-tr-group {
      border: none;
    }
  }

  &.pdf {
    display: block;
    overflow: hidden;
    .rt-th,
    .rt-tr {
      min-height: 20px;
    }
    .ReactTable .rt-table {
      overflow: hidden;
    }
  }
}
