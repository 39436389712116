.StackedPercentNav {
    height: 45px;
    padding-left: 100px;
    width: 100%;

    .stacked-percent-nav {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    .stacked-percent-item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        font-weight: 500;
        color: var(--black);
        font-size: 14px;
    }
}
