.container {
  &.isEditable {
    margin-bottom: 16px;
    background-color: white;
    border: 1px solid rgb(223, 225, 230);
    border-radius: 3px;
    overflow-wrap: break-word;
    padding: 16px;
  }

  &.disabled {
    background-color: rgb(223, 225, 230);
    pointer-events: none;
  }
}
.textArea {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  min-width: 100%;
  padding: 0;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
  padding-left: 15px;

  .pdfContainer {
    padding: 0;
    font-size: 10px;

    &.isKeyFindings {
      max-height: 510px;
      min-width: 100%;
      overflow: auto;
    }
    &.isKeyFindingsDesk {
      max-height: 570px;
      overflow: auto;
    }
    &.hasFilters {
      max-height: 480px;
      overflow: auto;
    }
    &.isKeyFindingsDeskFilters {
      max-height: 550px;
      overflow: auto;
    }
    &.overTime {
      margin-top: 10px;
      width: 1050px;
      max-height: 170px;
      overflow: auto;
    }
    &.overTimeFilters {
      margin-top: 10px;
      width: 1050px;
      max-height: 150px;
      overflow: auto;
    }
    &.vertical {
      width: 1050px;
      margin-top: 10px;
      max-height: 270px;
      overflow: auto;
    }
    &.verticalFilters {
      width: 1050px;
      margin-top: 10px;
      max-height: 260px;
      overflow: auto;
    }
    &.stacked {
      width: 1050px;
      margin-top: 10px;
      max-height: 260px;
      overflow: auto;
    }
    &.stackedFilters {
      width: 1050px;
      margin-top: 10px;
      max-height: 230px;
      overflow: auto;
    }
  }
  &.isPermitted:not(.isEditable):hover {
    background-color: rgb(235, 236, 240);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
    border-radius: 3px;
    padding-left: 15px;
  }
  &.isPdf:not(.isEditable):not(.isPermitted) :global(.public-DraftEditor-content) {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    padding-left: 10px;

    :global(.public-DraftStyleDefault-ol) {
      margin: 3px 0;
    }
    :global(.public-DraftStyleDefault-ul) {
      margin: 3px 0;
    }
  }
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.textError {
  margin-left: 10px;
  color: grey;
}

.countWrapper {
  margin: -10px 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
