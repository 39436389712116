@import "assets/mixins";

/*MODAL*/
.mentionSuggestions {
    position: absolute;
    max-height: 250px;
    overflow-x: auto;
    max-width: 400px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    background: white;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);

    z-index: 999;

    @include customScrollbar;
}

/*SUGGESTIONS ITEM*/
.mentionSuggestionsEntry {
    display: flex;
    flex-wrap: wrap;

    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.mentionSuggestionsEntry:active {
    background-color: #cce7ff;
}

.mentionSuggestionsEntryFocused {
    composes: mentionSuggestionsEntry;
    background-color: #e6f3ff;
}

/*CUSTOM*/
.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
    flex-basis: 100%;
    line-height: 20px;
    text-align: left;

    @include ellipsis;
}

.mentionSuggestionsEntryText {
    font-weight: bold;
}

.mentionSuggestionsEntryTitle {
    font-size: 80%;
    color: #a7a7a7;
}
