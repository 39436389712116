.FormButton {

  box-shadow: 0px 4px 25px rgba(83, 90, 143, 0.3);
  background-color: #004352;
  border-radius: 5px;
  display: inline-flex;
  transition: background-color .5s ease;

  .form-button {
    width: 283px;
    height: 60px;
    font-size: 18px;
    line-height: 1;
    color: #fff;

  }

  &:hover {
    // animation: jello 2s 1;
  }
}
