.DetailsPage {
  &.no-map {
    .StackedPercentNav {
      padding-left: 200px;
    }

    .map-container {
      display: none;
    }
    .content-container {
      max-width: 100%;

      .PageHeader {
        display: block;
      }
    }
    .FiltersInBlockButton {
      right: auto;
      left: 5px;
      top: 2px;
    }
    .single-bar-label {
      min-width: 200px;
      max-width: 200px;
    }
    .RoundArrowButton {
      left: 305px;
    }
  }

  .DesksCard {
    width: 160px;
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .GraphDisplay {
    overflow: hidden;
  }

  .BuildingsMap {
    height: 100%;
    .leaflet-container {
      min-height: calc(100vh - 47px);
    }
  }

  .graphs-block {
    margin-top: 90px;
    padding: 0 25px 30px 40px;
  }

  .details-page {
    display: flex;
    position: relative;
  }

  .details-content-scroll {
    height: 100vh;
    overflow: auto;
  }

  .details-content {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    position: relative;
  }

  .nav-menu {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 102;
    background-color: var(--white);
    width: 100%;
  }

  .map-container {
    min-width: 574px;
    max-width: 574px;
    height: calc(100vh - 47px);
    position: sticky;
    top: 47px;
  }

  .PageHeader {
    position: absolute;
    top: 45px;
    left: 20px;
    z-index: 10;
  }

  .content-container {
    width: 100%;
    max-width: calc(100% - 574px);
    position: relative;

    .PageHeader {
      display: none;
      position: relative;
      left: 0;
      margin-left: 40px;
    }
  }

  .RoundArrowButton {
    position: fixed;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: calc(305px + 574px);
    z-index: 1;
  }

  .CustomSelect {
    width: 200px;
    margin-left: auto;
    margin-right: 0;
  }

  .single-bar {
    width: 100%;
    max-width: 100%;
    height: 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .single-graph {
      width: calc(100% - 100px);
      pointer-events: none;
      > * {
        width: 100% !important;
      }
    }

    &-label {
      min-width: 100px;
      max-width: 100px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: var(--black);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .graphs-header {
    z-index: 2;
    position: relative;
    margin-bottom: 30px;
  }

  .graphs-wrapper {
    z-index: 1;
    position: relative;
  }

  .one-graph-wrapper {
    position: relative;
    border: 2px solid var(--summary-graph-border);
    padding: 5px 5px 0 15px;
    position: relative;

    .StackedPercentNav {
      width: calc(100% - 15px);
    }
  }

  .count-division-legend {
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    color: var(--dark-grey);
    text-align: center;
  }

  .details-title {
    font-size: 23px;
    font-weight: 500;
    color: var(--black);
  }

  .graphs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 1900px) {
  .DetailsPage {
    .DesksCard {
      margin-top: 20px;
    }
  }
}

@media (max-width: 1600px) {
  .DetailsPage {
    .map-container {
      min-width: 400px;
      max-width: 400px;
    }
    .content-container {
      max-width: calc(100% - 400px);
    }
    .RoundArrowButton {
      left: calc(305px + 400px);
    }
  }
}

@media (min-width: 1600px) and (max-width: 1650px) {
  .DetailsPage {
    .details-title {
      font-size: 19px;
    }
  }
}

@media (max-width: 1400px) {
  .DetailsPage {
    .details-title {
      font-size: 19px;
    }
  }
}

@media (max-width: 1300px) {
  .DetailsPage {
    .details-title {
      font-size: 16px;
    }
  }
}
