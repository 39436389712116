@import "assets/mixins";

.graphContainer {
  max-width: 1043px;

  .graphWrapper {
    width: 100%;
    border: 1px solid #E4E4E4;
    overflow-x: auto;
    overflow-y: hidden;

    @include customScrollbar;

    .graphInner {
      min-height: 550px;
      max-height: 550px;
    }
  }

  &.pdfMode {
    .graphWrapper {
      .graphInner {
        min-height: 300px;
        max-height: 300px;
      }
    }
  }
}
