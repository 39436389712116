.TabsContent {
  padding: 10px;

  .TabsContent-message {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    color: tomato;
  }

  .tabContent {
    display: flex;
    .tab-item {
      &-appear {
        opacity: 0;
      }
      &-appear-active {
        opacity: 1;
        transition: opacity 0.5s ease;
      }
      &-enter {
        opacity: 0;
        transition: opacity 0.5s ease;
        transform: translateX(100%);
      }
      &-enter-active {
        opacity: 1;
        transition: opacity 0.5s ease, transform 0.5s ease;
        transform: translateX(0%);
      }
      &-exit {
        opacity: 0;
        transition: opacity 0.5s ease;
        transform: translateX(0%);
      }
      &-exit-active {
        transition: opacity 0.5s ease, transform 0.5s ease;
        opacity: 0;
        transform: translateX(-100%);
      }
    }
  }
}
