.RestoreForm {

  .restore-form {

    &.disabled {

      .FormButton {
        background-color: #808088;

        .form-button {
          cursor: not-allowed;
        }
      }
    }
  }

  .FormButton {
    margin-top: 38px;
  }

  .BackButton {
    margin-bottom: 32px;
  }
}
