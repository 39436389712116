.container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  background: url("https://theplanetd.com/images/Tallest-mountains-in-the-world.jpg")
  center center / cover no-repeat;
  text-align: right;

  .subTitleContainer {
    height: 100%;
    flex-basis: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .subTitle {
      margin: auto 0;
      color: inherit;
    }
  }

  .filterContainer {
    height: 100%;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;

    .filterTable {
      color: inherit;

      .filterRow {
        background-color: initial;
        color: inherit;

        .filterCategory {
          max-width: 160px;
          padding-top: 0;
          padding-left: 0;
          padding-bottom: 0;
          color: inherit;
        }

        .filterOptions {
          padding: 0;
          color: inherit;
        }
      }
    }
  }

  .filterText {
    color: inherit;
  }
}
