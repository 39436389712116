@import 'assets/mixins/leftSidebar';

.listItem {
  @include listItemSlider();
}

.listSubItem {
  @include listSubItem();
}

.listSubItemSection{
  @include listSubItem();
  cursor: pointer;
}