.container {
  padding: 0 32px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;

  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;

  .navItem {
    padding: 14px 16px;

    font-size: 14px;
    letter-spacing: 0.25px;
    color: #3a3a3a;
    opacity: 1;

    &:hover {
      color: #00aad2;
      border-bottom: 1px solid #00aad2;
    }

    &.active {
      color: #00aad2;
      border-bottom: 1px solid #00aad2;
    }
  }
}
