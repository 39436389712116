.container {
  width: 100%;
  background-color: #cce9f2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  height: 50px;
  justify-content: space-evenly;
  
}
.header {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.wrapper > .header {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wrapper > .header ~ .header {
  width: 20%;
}
