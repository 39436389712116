.container {
  margin-left: 40px;
  margin-bottom: 60px;

  .logo {
    height: 120px;
    max-width: 200px;
    background: transparent left center / contain no-repeat;
  }
}
