.blockGraphs {
  padding: 0;
}

.verticalGraph {
  padding-right: 20px;
  padding-left: 10px;
}

.secondGraph {
  padding-top: 5px;
}
