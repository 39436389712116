.container {
  .graph {
    min-height: 655px;
    background: transparent;
    padding: 0;
    overflow: hidden;
  }

  .graphWrapper {
    padding-left: 69px;
    position: relative;
  }

  :global(.DayTimeGraphYNav) {
    position: absolute;
  }

  &.pdfMode {
    .graph {
      min-height: 300px;
    }
  }
}
