@import 'assets/mixins';

.container {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  @include customScrollbar;
}

.helperTextContainer {
  min-height: 19px;
  margin-bottom: 8px;

  overflow-x: auto;
  @include customScrollbar();
}
