.HistoryReportList {

  .history-report-list {
    // padding: 35px;
  }

  .HistoryReportListItem {

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

}
