.textBlock {
  font-size: 18px;
  line-height: 25px;

  ul {
    margin-bottom: 16px;
    padding-left: 32px;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      position: relative;

      &:before {
        position: absolute;
        top: 8px;
        left: -19px;

        content: '';
        width: 6px;
        height: 6px;
        background-color: black;
        border-radius: 50%;
      }
    }
  }

  &.pdfMode {
    > p,
    ul {
      margin-bottom: 8px;
    }
  }
}
