.MainButton.report-button {
  position: absolute;
  right: 160px;
  top: 0;

  .main-button {
    font-size: 18px;
    padding: 24px 35px;
  }
}

@media (max-width: 1400px) {
  .MainButton.report-button {
    right: 100px;
  }
}

@media (max-width: 1300px) {
  .MainButton.report-button {
    transform: translateY(50px);
  }
}

.graph {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // min-height: 350px;
  background-color: #f1f1f1; //var(--silver);
  border: #e4e4e4; //1px solid var(--earthly);
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 40px;
}

.blured {
  filter: blur(15px);
}

.low-blured {
  filter: blur(4px);
}

.buildings-map-marker {
  background-color: var(--turquoise) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--light-turquoise) !important;
  }
}

.buildings-map-tooltip {
  background-color: var(--white) !important;
  min-width: 200px !important;
  opacity: 1;
  &.leaflet-tooltip {
    border-radius: 7px;
    padding: 0;
  }

  &-content {
    position: relative;
    padding: 15px;
    min-width: 100px;
    white-space: pre-wrap;

    &-item {
      font-size: 14px;
      line-height: 19px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .name,
      .value {
        white-space: pre-wrap;
        font-weight: 500;
      }
      .name {
        color: var(--black);
      }
      .value {
        color: rgba(0, 0, 0, 0.64);
      }
    }
  }
}

.field-error {
  color: var(--error-color);
  font-size: 16px;
  margin-top: 12px;
}
