.container {
  height: calc(100% + 64px);
  width: calc(100% + 64px);
  margin: -32px;

  padding-top: 140px;
  padding-left: 40px;

  .header {
    align-content: flex-start;
    min-height: 162px;
    margin-bottom: 254px;
  }

  .btns > div {
    margin-right: 28px;
    padding: 6px 0;
  }
}
