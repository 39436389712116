.container {

  &:global(.pdfPage) {
    .image {
      max-width: 800px;
      max-height: 540px;
    }
  }

  .image {
    max-width: 1300px;
    width: 100%;
    height: auto;
  }
}
