.graph {
  width: 100%;
  height: 95%;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90%;
  padding: 10px 40px 40px 40px;
  height: 90%;

  .closeBtn {
    position: relative;
    left: calc(100% - 20px);
  }
}
