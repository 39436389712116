.ModalWindow {
  > div {
    > div {
      background-color: rgba(0, 0, 0, 0.6);

      > div {
        background: none !important;
        box-shadow: none !important;
        // width: 100%;
        // max-width: 998px;
        // background-color: pink !important;
        + div {
          background: none !important;
        }
      }
    }
  }

  .modal-window {
    width: 100%;
    height: 100%;
    background-color: #fff !important;
    padding: 90px 130px 50px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }

  .FormButton {
    margin-top: 80px;
  }
}

@media (max-height: 800px) {
  .ModalWindow {
    .modal-window {
      padding: 50px 130px 30px;
    }
  }
}
