@import '../../../../assets/mixins';

.container {
  @include pdfContainer;

  :global(.pdfPage) {
    @include pdfPage;
    padding: 40px 40px;
    font-size: 10px;
    overflow-y: auto;
  }

  :global(.hasPageCounter:before) {
    @include pdfPageCounter;
  }

  :global(.reportLogo) {
    @include pdfPageLogo;
  }
}
