.container {
  width: 100%;
  height: 100vh;
  min-height: 800px;
  display: flex;
  margin: 0 auto;

  .formContainer,
  .imageContainer {
    height: 100%;
    width: 50%;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px 100px 30px;

    .form {
      width: 100%;
    }
  }

  .imageContainer {
    position: relative;
    background: center center no-repeat;
    background-size: cover;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 67, 82, 0.6);
    }
  }
}


