.leaflet-container {
  width: 100%;
  height: 100%;
}

.mapContainer {
  width: 100%;
  height: calc(100% - 50px);
  padding: 16px;
}
