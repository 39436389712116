.HistoryReportPopup {
  position: absolute;
  top: 100%;
  left: 0;
  height: 0;
  transition: opacity 0.4s ease-out, transform 0.4s ease;
  will-change: transform, opacity;
  overflow: hidden;
  max-width: 400px;
  transform: translateY(-20px);
  max-height: 150px;

  .history-report-popup {
    padding: 20px;
    background: var(--almost-white);
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    max-height: 150px;
    overflow: auto;
  }

  .building-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
