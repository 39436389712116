.loginForm {
  margin-bottom: 40px;

  &.disabled {
    :global(.FormButton) {
      background-color: #808088;

      :global(.form-button) {
        cursor: not-allowed;
      }
    }
  }

  .fieldError {
    color: var(--error-color);
    font-size: 16px;
    margin-top: 12px;
  }

  :global(.FormikInput) {
    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  .restoreBlock {
    display: flex;
    font-size: 19px;
    line-height: 1;
    margin-top: 40px;

    .text {
      color: #686868;
      margin-right: 7px;
      font-size: 19px;
      line-height: 1;
    }
    .button {
      color: var(--turquoise);
      font-weight: 500;
      font-size: 19px;
      line-height: 1;
    }
  }

  :global(.FormButton) {
    margin-top: 70px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-text-fill-color: #686868;
  }
}

