@import 'assets/mixins';

.container {
  width: 100%;
  height: 100%;
  padding: 16px;
}
.largeCardContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: auto;
}
.imageContainer {
  width: 30%;
  border-right: 1px solid #c9c9c9;
}
.infoContainer {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.btnWrapper {
  width: 30%;
}
.buildingType {
  height: 20%;
  width: 100%;
  justify-content: end;
  padding-right: 5%;
  padding-top: 2%;
}
.buildingType2 {
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5%;
}
.infoWrapper {
  margin-left: 10%;
  margin-top: 5%;
}
.address {
  margin-top: 3%;
}
.additionalInfo {
  margin-top: 15%;
}
