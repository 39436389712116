.QrConfirmForm {
  svg {
    display: block;
    margin: auto;

    path {
      &:last-child {
        fill: var(--turquoise);
      }
    }
  }

  .qr-confirm-form {
    margin-top: 30px;
    position: relative;

    &.disabled {
      .FormButton {
        background-color: #808088;
        .form-button {
          cursor: not-allowed;
        }
      }
    }
  }

  .FormButton {
    margin-top: 30px;
  }

  .inputForCopyTokens {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}
