@import 'assets/mixins';

.title {
  padding-left: 20px;
  @include ellipsis;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.inputWrapper {
  width: 100%;
}
.warning {
  color: rgb(255, 204, 0);
}
.btnsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.graph {
  width: 100%;
  height: 95%;
}

.error {
  color: red;
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 35%;
  border-radius: 10px;
  padding: 10px 40px 40px 40px;
  height: 70%;
}
.formWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.formContainer {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sbmtBtn {
  align-self: flex-end;
}
