@import 'assets/mixins';

.emptyContainer {
  width: 100%;
  height: 100%;
  padding: 16px;
}
.editableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: 0px 2px 8px rgba(59, 59, 59, 0.25);
  border-radius: 5px;
  margin-bottom: 20px;
}
.header {
  padding: 16px;
}

tr {
  height: 30px !important;
}
.sortedIconsWrapper {
  display: flex;
  flex-direction: column;
  // margin-top: -25px;
}

.attributeContainer {
  display: flex;
  flex-direction: row;
}

th {
  max-width: 100px;
  min-width: 100px;
  padding: 0 !important;
  overflow-x: auto;
  text-align: center !important;
}

td {
  height: 30px !important;
  padding: 5px 15px 5px 5px !important;
  text-align: center !important;
}

th,
td {
  min-width: 75px !important;
}
