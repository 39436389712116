.DayTimeGraphYNav {
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100% - 79px);
  background-color: var(--white);
  width: 105px; //86px;
  padding: 7px 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;

  .init-value {
    position: absolute;
    bottom: 10px;
    left: 5px;
  }

  .day-time-graph-y-nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #e4e4e4;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
}
