// TODO: Split and move to mixins folder
$scrollbar-track-color: #ededed;
$scrollbar-thumb-color: #ddd;

@mixin customScrollbar() {
  &::-webkit-scrollbar-track {
    width: 12px;
    background-color: $scrollbar-track-color;
    border-radius: 8px;
  }

  &::-webkit-scrollbar {
    background-color: $scrollbar-track-color;
    border-radius: 8px;

    &:vertical {
      width: 12px;
    }
    &:horizontal {
      height: 12px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $scrollbar-thumb-color;
    -webkit-box-shadow: inset 0 0 0 3px $scrollbar-track-color;
  }
}

@mixin filterContainer {
  position: absolute;
  top: calc(97px + 40px);
  right: 32px;

  height: 75%;
  overflow: hidden;

  will-change: transform;
  transition: transform 0.5s ease-in-out;
  transform: translateX(calc(100% + 45px));
  z-index: 101;

  @include filterContainerBorder;

  &.active {
    transform: translateX(0);
  }
}

@mixin filterContainerBorder {
  border-radius: 11px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.25);
}

@mixin filterBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 350px;
  height: 100%;
  background-color: var(--light-grey);
  border-radius: 11px;

  text-align: left;

  .content {
    flex-grow: 1;
    padding: 16px;
    overflow: auto;
    z-index: 1;

    @include customScrollbar;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin pdfContainer {
  width: 297mm;
  margin: 0 auto;

  counter-reset: page-counter 0;

  * {
    -webkit-print-color-adjust: exact;
  }
}

@mixin pdfPage {
  page-break-after: always;
  position: relative;
  // Chrome somewhere lose 0.5mm when creating a PDF
  height: 209.5mm;
  overflow: hidden;

  counter-increment: page-counter 1;
};

@mixin pdfPageCounter {
  content: counter(page-counter);
  position: absolute;
  bottom: 20px;
  right: 40px;

  font-weight: 500;
  font-size: 16px;
  text-align: right;
}

@mixin pdfPageLogo {
  position: absolute;
  bottom: 20px;
  left: 40px;
  height: 26px;
  width: auto;
  z-index: 10;
  display: block;
}
