.container {
  .totalCapacityBox {
    margin-bottom: 34px;
    max-width: 468px;

    border: 1px solid #00aad2;
    border-radius: 8px;

    text-align: center;
    line-height: 77px;
    color: #00aad2;
    font-size: 20px;
    font-weight: 500;
  }

  &:global(.pdfPage) {
    .totalCapacityBox {
      margin-bottom: 0;
      max-width: unset;

      font-size: 18px;
      line-height: 50px;
    }
  }
}
