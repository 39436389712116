@import "assets/mixins";

.title {
  @include ellipsis;
}

.subTitle {
  font-size: 14px;
  line-height: 16px;
  min-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);

  @include ellipsis;
}