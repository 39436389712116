.dataCard {
  width: 145px;
  height: 93px;

  text-align: center;
  color: var(--white);
  font-weight: 500;

  border-radius: 8px;

  .cardTitle {
    padding: 8px 8px 4px;

    font-size: 12px;
    line-height: 16px;
    color: inherit;

    border-bottom: 1px solid #fff;
  }

  .firstLine {
    padding: 8px 8px 0;

    font-size: 18px;
    line-height: 24px;
    color: inherit;

    &.singleValue {
      padding: 8px;
      //line-height: 48px;
    }
  }

  .secondLine {
    padding: 0 8px 8px;

    font-size: 14px;
    line-height: 24px;
    color: inherit;
  }

  .noValue {
    padding: 8px;

    font-size: 18px;
    line-height: 48px;
    color: inherit;
  }

  &.pdfMode {
    width: 120px;
    height: 75px;

    .cardTitle {
      font-size: 10px;
      line-height: 14px;
    }

    .firstLine {
      font-size: 16px;
      line-height: 20px;
    }

    .secondLine {
      font-size: 10px;
      line-height: 18px;
    }

    .noValue {
      font-size: 14px;
      line-height: 40px;
    }
  }
}
