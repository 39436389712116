.container {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.title {
  padding-left: 30px;
}
.seriesContainer {
  margin-top: 20px;
  overflow-x: auto;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
}
.seriesFormContainer {
  width: 43%;
  margin-left: 30px;
  margin-top: 20px;
  overflow-x: auto;
  padding-right: 15px;
  box-sizing: content-box;
}
.seriesBody {
  display: flex;
  flex-direction: row;
  height: 90%;
  justify-content: space-between;
  width: 100%;
}
