.cardLines {
  margin-top: 40px;
  padding: 0 25px 0 40px;

  .cardLine {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .cardLineTitle {
      font-size: 18px;
      line-height: 25px;
      font-weight: 500;
      color: var(--black);
      width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    @media (max-width: 1900px) {
      .cardLineTitle {
        min-width: 100%;
      }
    }
  }
}

