@import "assets/mixins";

.container {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .title {
    padding-bottom: 15px;
  }

  .graphContainer {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 450px;
    border: 1px solid #e4e4e4;
    margin-bottom: 16px;
    margin-top: 10px;

    .barSection {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      border-left: 1px solid #e4e4e4;
      @include customScrollbar;

      .oneDayContainer {
        // max-width: calc(25% + 7px);
        // min-width: calc(25% + 7px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        &:first-child {
          .oneDayLabel {
            border-left: 0;
          }
        }
        .hourGraph {
          position: relative;
          height: calc(100% - 48px);
          background: var(--white);
          padding: 0;
          white-space: nowrap;
        }
        &:last-child {
          margin-right: 0;
          // max-width: 25%;
          // min-width: 25%;
        }

        &:last-child:nth-child(n + 5) {
          .oneDayLabel {
            border-right: 0;
          }
        }

        .oneDayGraph {
          flex-grow: 1;
          margin-right: -4px;
        }
        .oneDayLabel {
          margin-top: 8px;
          height: 40px;
          margin-right: 2px;
          line-height: 40px;
          text-align: center;
          border: 1px solid #e4e4e4;
          border-bottom: 0;
          overflow-wrap: normal;
          overflow: hidden;
        }
      }
    }
  }

  &.pdfMode {
    .graphContainer {
      height: 275px;
      margin-bottom: 8px;
    }

    .barSection {
      .oneDayContainer {
        margin-right: -8px;
        .oneDayLabel {
          line-height: 25px;
        }
      }
    }
  }
}
