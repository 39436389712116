.graphLine {
  height: 5px;
  width: 100%;
  position: absolute;
  left: 0;
  opacity: 0.7;

  cursor: pointer;
  z-index: 9;
}
