@import 'assets/mixins';

.helperTextContainer {
  min-height: 19px;
  margin-bottom: 8px;

  overflow-x: auto;
  @include customScrollbar();
}

.btnWrapper {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: end;
}

.container {
  height: calc(100% - 67px);
  overflow-y: scroll;
}

.advanceSelector {
  margin: 10px;
  width: 485px;
  // height: 50px;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.selectorHeader {
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}