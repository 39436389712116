@import "assets/mixins";
.container {
  .graphWrapper {
    position: relative;
    display: flex;
    border: 1px solid #e4e4e4;

    .graphInner {
      flex-grow: 1;
      overflow-x: auto;
      overflow-y: hidden;

      @include customScrollbar;

      .graph {
        height: 400px;
        background: var(--white);
        padding: 0;
      }
    }
  }
  &.pdfMode {
    .graphWrapper {
      max-width: 805px;
      border-radius: 5px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      .graphInner {
        border-radius: 5px;

        .graph {
          height: 270px;
          max-width: 800px;
        }
      }
    }
  }
}
