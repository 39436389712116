.App {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  background: #fff;
  position: relative;

  .app {
    width: 100%;
    min-height: 100vh;
  }

  * {
    font-family: var(--main-font);
  }

  ::selection {
    color: #fff;
    background-color: var(--turquoise);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}

a {
  text-decoration: none;
}
