.container {
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.highlight {
    :global(.react-date-picker__wrapper) {
      border-color: var(--turquoise);
      background-color: white;
    }

    :global(.react-date-picker__inputGroup) {
      z-index: unset;
    }
  }

  .title {
    font-size: 1rem;
    font-weight: 500;
    color: var(--black);
    margin-bottom: 8px;
  }

  :global(.react-date-picker__wrapper) {
    border: 1px solid #C9C9C9;
    border-radius: 5px;
    height: 51px;
    padding: 6px;
  }

  :global(.react-date-picker__inputGroup) {
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
    padding: 0 15px 0 0;
    position: relative;
    z-index: -1;
  }

  :global(.react-date-picker__calendar-button) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  :global(.react-calendar__tile--active) {
    background: var(--turquoise);

    &:hover {
      background: var(--sea-blue);
    }
  }

  :global(.react-calendar__tile--hasActive) {
    background: var(--turquoise);

    &:hover {
      background: var(--sea-blue);
    }
  }
}
