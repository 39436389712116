.PageHeader {
  margin-bottom: 50px;

  .title {
    font-size: 32px;
    line-height: 44px;
    font-weight: 500;
    color: var(--black);
    margin-bottom: 15px;
  }

  .text {
    font-size: 19px;
    line-height: 26px;
    font-weight: 500;
    color: var(--dark-grey);
  }
}

.pdf {
  .PageHeader {
    margin-bottom: 15px;
    .title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 5px;
    }
    .text {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
