.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;

  background: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 9;

  .icon {
    margin-right: 15px;
  }

  .btnGroup {
    margin-left: auto;
  }
}
