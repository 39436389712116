.errorContainer {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .errorIcon {
    margin-bottom: 16px;
    font-size: 4rem;
    color: #5c5c5c;
  }

  .errorText {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 16px;
  }
}