.propertyInfoContainer {
  padding: 40px 25px 20px 40px;
  border-bottom: 3px solid var(--almost-white);
  display: flex;

  .property-info-image {
    object-fit: cover;
    width: 100%;
    display: block;
  }

  .propertyImage {
    width: 335px;
    background: transparent center 10px / contain no-repeat;
  }

  .propertyInfoList {
    flex-grow: 1;
    padding: 10px 0 10px 30px;

    .propertyInfoListItem {
      color: var(--black);
      font-size: 19px;
      line-height: 26px;

      .label {
        font-weight: 500;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
