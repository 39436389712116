@import 'assets/mixins';

.container {
  display: block !important;
  cursor: pointer;
  @include ellipsis;
};

.checkbox {
  padding: 0 !important;
}

.id {
  &:hover {
    text-decoration: underline;
  }
}
