.StatsTableHeadCell {
  padding: 4px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .label {
    margin-right: 10px;
    font-size: 15px;
    color: var(--black);
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
    max-width: calc(100% - 25px);
    display: block;
    white-space: normal;
  }

  .icon {
    display: block;

    .pic {
      display: block;
    }
  }

  &.pdf {
    padding: 0;

    .label {
      max-width: calc(100% - 10px);
      font-size: 12px;
    }
    .icon {
      display: none;
    }
  }
}
