.FiltersInBlockButton {
  position: relative;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.17);
  border-radius: 11px;
  display: inline-flex;
  min-width: 400px;
  background-color: var(--white);

  .filters-in-block-button {
    padding: 15px 15px 15px 47px;
    font-weight: 500;
    color: var(--black);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    position: absolute;
    width: 17px;
    height: auto;
    top: 15px;
    left: 15px;
  }
}
