.container {
  .graphWrapper {
    max-width: 1400px;
    min-height: 600px;
    display: flex;
    padding: 20px 0;
    border: 1px solid var(--graph-border);

    .graph {
      background: var(--white);
      padding: 0;
      overflow: hidden;
      width: 100%;
    }
  }

  &.pdfMode {
    .graphWrapper {
      min-height: 300px;
    }
  }
};
