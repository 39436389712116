.AccordionSidebarListHeader {
  padding: 10px 0 10px 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid var(--accordion-sidebar-header-border);
  position: relative;

  .accordion-sidebar-list-header {
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    display: block;
    width: auto;
    height: auto;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .arrow-icon {
    margin: 0 30px 0 auto;
    display: block;
    transform: rotate(180deg);
    will-change: transform;
    width: 10px;
    height: auto;
    transition: transform 0.2s ease-out;
  }
}
