@import "assets/mixins";

.container {
  flex-grow: 1;
  max-height: 155px;
  margin-left: 24px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;

  overflow-x: auto;
  @include customScrollbar();

  .legendItem {
    height: 16.5%;
    max-width: 175px;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &:nth-child(n+7) {
      margin-left: 24px;
    }

    &:first-child {
      margin-top: auto;
    }

    &:last-child:not(:nth-child(n+7)) {
      margin-bottom: auto;
    }

    .icon {
      min-width: 16px;
      height: 16px;
      border-radius: 50%;
    }

    .label {
      margin-left: 8px;
      width: 160px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
      cursor: pointer;
    }

    .lineThrough {
      text-decoration: line-through;
    }
  }

  &.pdfMode {
    max-height: 125px;

    .legendItem {
      max-width: 165px;
    }
  }
}

@media (max-width: 960px) {
  .container {
    flex-basis: 100%;
    margin-top: 24px;

    .legendItem:nth-child(-n+5) {
      margin-left: 0;
    }

    &.pdfMode {
      flex-basis: unset;
      margin-top: 0;

      .legendItem:nth-child(-n+5) {
        margin-left: unset;
      }
    }
  }
}
