.container {
  margin-left: 5%;
  width: 95%;
  height: 97%;
  display: flex;
  flex-direction: column;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 62%;
  height: 700px;
  overflow-y: auto;
  margin-top: 5%;
  padding-top: 5px;
}
.error {
  color: red;
}
.textField {
  margin-top: 40px;
}

.sbmtBtn {
  margin-top: 100px !important;
  align-self: flex-end;
}
