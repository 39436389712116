.archiveItem {
  width: 400px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.HistoryReportListTitle {
  .history-report-list-title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
    color: var(--strange-black);
    padding: 20px;
    margin-left: 10px;
  }

  .date {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .date-start,
  .date-end {
    white-space: nowrap;
  }
}
