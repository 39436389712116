.container {
  padding: 32px;
  width: 100%;
  min-height: 90px;

  position: absolute;
  top: 0;
  left: 0;

  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
  will-change: transform;

  background-color: var(--sea-blue);
  z-index: 99;

  &.isOpen {
    transform: translateY(0);
  }

  .closeBtn {
    cursor: pointer;

    .closeIcon {
      display: block;
      width: 20px;
      height: auto;
    }
  }
}
