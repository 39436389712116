.DesksCard {
  border-radius: 7px;
  overflow: hidden;
  width: 122px;
  color: var(--white);

  &.separated-label-and-value {
    .quantity {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  &.invert {
    .content {
      .percent {
        order: 1;
        font-size: 15px;
        line-height: 17px;
      }
      .quantity {
        font-size: 19px;
        line-height: 26px;
      }
    }
  }

  &.with-percent {
    .quantity {
      font-size: 15px;
      line-height: 17px;
    }
  }

  .header {
    min-height: 26px;
    border-bottom: 1px solid #fff;
    padding: 7px 7px 3px;
  }

  .title {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--white);
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 65px; //57px;
    padding: 7px;
  }

  .percent,
  .quantity {
    color: var(--white);
    font-size: 19px;
    line-height: 26px;
    font-weight: 500;
  }
}

.pdf {
  .DesksCard {
    margin: 0 20px 10px 0;
    &:nth-last-child(-n+3) {
      margin-bottom: 0;
    }

    .content {
      min-height: 50px;
    }

    .percent,
    .quantity {
      font-size: 16px;
      line-height: 20px;
    }

    &.with-percent {
      .quantity {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
