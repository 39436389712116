.mapContain {
  width: 100%;
  height: 50%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding-top: 5%;
}

.container {
  height: 500px;
}

.title {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.header {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.error {
  color: red;
  text-transform: capitalize;
}