.StaticSidebar {
  height: 100vh;
  width: 305px;
  overflow-y: auto;
  overflow-x: hidden;

  .static-sidebar {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-right: 0;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
  }

  .SwitchButton {
    height: 70px;
    padding-left: 87px;

    .switch-button {
      cursor: auto;
    }

    .icon {
      height: 70px;
    }
  }

  .ClientName {
    margin: 0 0 60px 40px;
  }

  .Copyright {
    margin-top: auto;
  }

  .StaticHeaderBanner {
    margin: 0 0 20px 40px;
  }

  .SwitchButton {
    cursor: default;
    .switch-button {
      cursor: default;
    }
  }
}
