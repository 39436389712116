.FilterButton {
  .badge {
    .MuiBadge-badge {
      height: 22px;
      min-width: 22px;
      font-size: 14px;

      top: 10px;
      right: 5px;

      background: var(--turquoise);
      color: white;
    }
  }

  .button {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--dark-grey);
    transition: .2s ease-in-out;

    &.active {
      background: var(--turquoise);
    }

    &.withBadge {
      border: 3px solid var(--turquoise);
      background: unset;

      .icon {
        fill: var(--turquoise);
      }
    }
  }

  .icon {
    fill: white;
    width: 29px;
    height: auto;
  }
}
