.FiltersInBlockList {
  height: 100%;

  .filters-in-block-list {
    padding: 20px 50px 10px 40px;
    height: 100%;

    .FiltersInBlockListHeader {
      font-size: 17px;
      line-height: 23px;
      font-weight: 500;
      color: var(--black);
      margin-bottom: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-left: 10px;
    }

    .items {
      height: calc(100% - 48px);
      padding-top: 10px;
      padding-left: 10px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--light-light-grey);
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--grey);
      }
    }

    .Checkbox {

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
