.Copyright {

  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .item {
      color: var(--silver);
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}
