.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #cce9f2;
}

.row:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.iconWrapper {
  width: 5%;
  border-left: 1px solid #cce9f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
