.Checkbox {
  display: block;

  &.is-strict {
    .checkbox {
      font-size: 14px;
      line-height: 20px;
      color: var(--accordion-sidebar-text);
      font-weight: 400;
      > span::before {
        border-radius: 0;
      }
    }
  }

  .checkbox {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: var(--black);
    font-size: 1rem;
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;

    &:hover {
      > span {
        color: var(--turquoise);
      }
    }
  }

  .checkbox > input {
    appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: grey;
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
  }

  /* Span */
  .checkbox > span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    color: var(--black);
    transition: color 0.2s ease;
    user-select: none;
  }

  /* Box */
  .checkbox > span::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    margin-right: 16px;
    border: solid 2px; /* Safari */
    border-color: var(--black);
    border-radius: 2px;
    width: 22px;
    height: 22px;
    vertical-align: top;
    transition: border-color 0.2s, background-color 0.2s;
  }

  /* Checkmark */
  .checkbox > span::after {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 4px;
    width: 13px;
    height: 11px;
    background: url("../../../assets/images/icons/checkmark.svg") center center /
      contain no-repeat;
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  /* Checked, Indeterminate */
  .checkbox > input:checked,
  .checkbox > input:indeterminate {
    background-color: var(--turquoise);
  }

  .checkbox > input:checked + span,
  .checkbox > input:indeterminate + span {
    color: var(--turquoise);
  }

  .checkbox > input:checked + span::before,
  .checkbox > input:indeterminate + span::before {
    border-color: var(--turquoise);
    background-color: var(--light-grey);
  }

  .checkbox > input:checked + span::after,
  .checkbox > input:indeterminate + span::after {
    border-color: var(--turquoise);
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  .checkbox > input:indeterminate + span::after {
    border-left: none;
    transform: translate(4px, 3px);
  }

  /* Hover, Focus */
  .checkbox:hover > input {
    opacity: 0.04;
  }

  .checkbox > input:focus {
    opacity: 0.12;
  }

  .checkbox:hover > input:focus {
    opacity: 0.16;
  }

  /* Active */
  .checkbox > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
  }

  .checkbox > input:active + span::before {
    border-color: var(--grey);
  }

  .checkbox > input:checked:active + span::before {
    border-color: transparent;
    background-color: var(--sea-blue);
  }

  /* Disabled */
  .checkbox > input:disabled {
    opacity: 0;
  }

  .checkbox > input:disabled + span {
    color: var(--grey);
    cursor: initial;
  }

  .checkbox > input:disabled + span::before {
    border-color: currentColor;
  }

  .checkbox > input:checked:disabled + span::before,
  .checkbox > input:indeterminate:disabled + span::before {
    border-color: transparent;
    background-color: currentColor;
  }
}
