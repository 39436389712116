.DayTimeGraphYNavItem {
  display: block;
  padding-right: 5px;
  color: var(--dark-grey);
  flex-basis: 25%;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;

  .day-time-graph-y-nav-item {
    transform: translateY(-120%);
  }
}
