.container {
  .header {
    margin-bottom: 16px !important;
  }

  .pieContainer {
    display: flex;
    flex-wrap: wrap;

    .pieWrapper {
      width: 155px;
      height: 155px;
    }
  }

  &.pdfMode {
    .header {
      margin-bottom: 8px !important;
    }

    .pieContainer{
      .pieWrapper {
        width: 125px;
        height: 125px;
      }
    }
  }
}

