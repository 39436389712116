.wrapper {
  width: 300px;
}
.listContainer {
  margin-top: 10px;
  display: flex;
  padding-left: 10px;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  height: 100px;
  box-shadow: 2px 3px 30px 1px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.headerItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}
.link {
  width: 90%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.url {
  margin-top: 5px;
  font-size: 18px;
}
.btnWrapper {
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
