@import 'assets/mixins';

.container {
  @include pdfContainer;

  .servicePage {
    @include pdfPage;

    z-index: 3;
  }

  .blockContainer {
    margin-top: 0px;
    // padding: 20px 0px;
  }

  .cardsContainer {
    padding-top: 5px;
    padding-bottom: 10px;
    width: 550px;
  }

  .pdfPage {
    @include pdfPage;
    padding: 20px 20px;
    // padding-right: 30px;

    max-width: 100%;
    max-height: 100%;
  }

  .hasPageCounter:before {
    @include pdfPageCounter;
    bottom: 10px;
    right: 25px;
  }

  .page {
    height: 209.5mm;
  }

  .keyFindings {
    height: calc(209.5mm * 2);
  }

  .occupancyOverTime {
    // height: 100%;
    // padding-bottom: 50px;
  }

  .footer {
    position: absolute;
    color: black;
    z-index: 2;
    left: auto;
    right: 40px;
    font-weight: 500;
  }
  .footerLogo {
    position: absolute;
    color: black;
    z-index: 2;
    left: 40px;
  }
}
