.scaleContainer {
  // margin-top: 2%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.scaleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: absolute;
}
.progressBarContainer {
  width: 100%;
  position: relative;
}
.aligner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textScale {
  color: rgb(171, 171, 171);
}
.valueWrapper {
  width: 10px;
  border: 1px solid #757575;
  background-color: #9c9c9c;
  position: absolute;
}
.scale {
  width: 1px;
  background-color: rgb(171, 171, 171);
  float: left;
}
.progress {
  margin-top: 5px;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  line-height: 75px;
  font-size: 35px;
  font-family: 'Segoe UI';
  animation-direction: reverse;
  display: flex;
  justify-content: center;
  background: #e5405e;
  background: linear-gradient(to right, #e5405e 0%, #ffdb3a 45%, #3fffa2 100%);
}

.popoverContent {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background-color: #CCD5AE;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: 120px;
    margin-top: -45px;
    margin-left: -50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  .popoverWrapper:hover .popoverContent {
    z-index: 10;
    opacity: 1;
    position: absolute;
    visibility: visible;
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popoverMessage {
    text-align: center;
  }
  