.container {
  width: 100%;
  flex-basis: 100%;
  padding-left: 30px;
  padding-right: 15px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mainLine {
    margin-bottom: 5px;
  }
}
