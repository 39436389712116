.container {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  > div:not(:first-child) {
    margin-left: 16px;
  };
};

@media (max-width: 1280px) {
  .container {
    justify-content: flex-start;
  };
};

@media (max-width: 960px) {
  .container {
    flex-wrap: wrap;

    > div:not(:first-child) {
      margin-left: 0;
    };
  };
};