.container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: url("https://theplanetd.com/images/Tallest-mountains-in-the-world.jpg")
  center center / cover no-repeat;
  text-align: right;

  .subTitle {
    color: inherit;
  }
}
