.BackButton {

  position: relative;

  .arrow {
    width: 11px;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  .back-button {
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    color: var(--sea-blue);
    padding-left: 23px;
    position: relative;
  }
}
