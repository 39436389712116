@import 'assets/mixins';

.container {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.cardLargeContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.largeCardInfoContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 0 10px;
  padding: 5px;
  box-shadow: 0px 2px 8px rgba(59, 59, 59,  25%);
  border-radius: 5px;
}

.largeCard {
  display: 'flex';
  margin: '3px';
}

.largeCardWidgets:hover {
  cursor: pointer;
  background-color: #f4f5f7;
}