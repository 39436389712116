.timeGraphContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.container {
  width: 100%;
  height: 100%;
  padding: 16px;
}
