.container {
  padding-left: 30px;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.wrapper {
  width: 97%;
}
.bodyContainer {
  margin-top: 20px;
  padding-bottom: 20px;
}
