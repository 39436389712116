.HistoryReportListTitle {


  .history-report-list-title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
    color: var(--strange-black);
    padding-left: 40px;
  }

  .date {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
  .date-start,
  .date-end {
    white-space: nowrap;
  }
}

@media (max-width: 1600px) {
  .HistoryReportListTitle {
    .date {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .date-separator {
      display: none;
    }
    .history-report-list-title {
      padding-left: 25px;
    }
  }
}
