.reportTextContainer {
  max-width: 1043px;

  p {
    position: relative;
    padding-left: 20px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      width: 12px;
      height: 12px;
      transform: scale(0.5) translateX(-6px);
      background-color: var(--black);
      border-radius: 50%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.pdfMode {
    max-width: 100%;

    :global(.MuiTypography-body2) {
      font-size: 1em;
    }

    p {
      padding-left: 10px;

      &:before {
        content: '';
        left: 0;
        top: 3px;
        width: 12px;
        height: 12px;
        transform: scale(0.33) translateX(-12px);
        background-color: var(--black);
        border-radius: 50%;
      }
    }
  }
}
