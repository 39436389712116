.container {

  .textArea {
    font-size: 20px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
  }

 .buttonContainer {
   display: flex;
   justify-content: center;
   margin-top: 20px;
 }
  
}
