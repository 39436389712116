.pageRow {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  .text {
    flex-grow: 1;
    padding-left: 24px;
    position: relative;
    overflow-x: hidden;

    &:after {
      content: " ....................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................";
      position: absolute ;
      padding-left: 8px;
    }
  }

  .pageCounter,
  .checkbox {
    padding-left: 8px;
  }
}
