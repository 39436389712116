@import "assets/mixins";

.list {
  width: 450px;
  max-width: 450px;
  max-height: 275px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;

  @include customScrollbar
}

.noReports {
  font-size: 18px;
}
