@import 'assets/mixins';

.cardContainer {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: auto;
  @include customScrollbar;
}

.cardContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 2px 8px rgba(59, 59, 59, 0.25);
  border-radius: 5px;
  margin-bottom: 20px;
}
.infoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.infoWrapper {
  margin-left: 10px;
  margin-top: 10px;
}
.address {
  margin-top: 7px;
}
.info {
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
