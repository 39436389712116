.RestorePage {
    .restore-page {
        width: 100%;
        height: 100vh;
        min-height: 800px;
        display: flex;
        width: 100%;
        margin: 0 auto;
      }
    
      .restore-form-container,
      .restore-image-container {
        height: 100%;
        width: 50%;
      }
    
      .restore-form-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 60px 100px 30px;
      }
    
      .restore-image-container {
    
        background: center center no-repeat;
        background-size: cover;
    
        .filter {
          width: 100%;
          height: 100%;
          background: rgba(0, 67, 82, 0.6);
        }
      }
    
      .restore-form {
        width: 100%;
      }
}
