.summaryPageFilters {
  width: 100%;
  max-width: 1000px;
  background: var(--light-grey);

  height: 625px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .header {
    flex-basis: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 30px 45px 20px;
    background-color: var(--white);

    .icon {
      margin-right: 23px;
    }

    .title {
      font-size: 20px;
      line-height: 27px;
      font-weight: 500;
      color: var(--black);
    }
  }


  .filterListsContainer {
    position: relative;
    flex-basis: 100%;
    height: calc(100% - 77px - 80px);

    display: flex;
    justify-content: space-between;
  }

  .footer {
    flex-basis: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 40px 30px;

    .MainButton {

      border-radius: 4px;
      box-shadow: none;

      .main-button {
        font-size: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        min-width: 192px;
      }
    }
  }
}
