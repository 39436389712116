.container {
  margin-left: 5%;
  width: 95%;
  height: 97%;
  display: flex;
  flex-direction: column;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 800px;
  overflow-y: auto;
  width: 60%;
  padding-top: 5%;
}

.textField {
  margin-top: 40px;
}

.error {
  color: red;
}

.name {
  color: red;
}

.sbmtBtn {
  margin-top: 40px !important;
  align-self: flex-end;
}
