.MainButton {
  background: var(--turquoise);
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.34);
  display: inline-flex;
  transition: 0.5s background-color ease;

  .main-button {
    color: #fff;
    font-size: 23px;
    line-height: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 29px 50px;
    width: 100%;
  }
}
