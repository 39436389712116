.container {
  width: 100%;
}

.colorsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.titlesWrapper {
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.redText {
  color: red;
  text-transform: uppercase;
}

.greenText {
  color: green;
  text-transform: uppercase;
}

.leftTitle {
    width: 50%;
    text-align: start;
}

.rightTitle {
    width: 50%;
    text-align: end;
}