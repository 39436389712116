@import 'assets/mixins';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90%;
  border-radius: 10px;
  padding: 10px 40px 40px 40px;
  height: 90%;
}

.graph {
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.title {
  padding-left: 20px;
  @include ellipsis;
}

.tabsContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.finishWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
